<script>
export default {
  data() {
    return {
    };
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
          id: null,
          title: '',
          memo: '',
          urlAvatar: '',
          urlCover: ''
        }
      }
    }
  },
  components: {
  },
  methods: {
    getSeasonEpisode() {
      if(this.data.nSeason > 0 && this.data.nEpisode > 0)
        return 'S' + this.data.nSeason + 'E' + this.data.nEpisode + ' ';
      return '';
    }
  },
  computed: {
    getSecondsOfEpisode() {
      return parseInt(this.data.stat.seconds / 60);
    }
  }
};
</script>

<template>
  <div class="card team-box ribbon-box ribbon-fill">
    <div class="card-body px-4">
      <div class="ribbon ribbon-danger" v-if="data.flags.isNew"><small>New</small></div>
      <div class="row align-items-center team-row">
        <div class="col team-settings">
          <div class="row align-items-center">
            <div class="col text-end dropdown">
              <a href="javascript:void(0);" data-bs-toggle="dropdown" aria-expanded="false"><i class="ri-more-fill fs-17"></i></a>
              <ul class="dropdown-menu dropdown-menu-end overflow-visible">
                <li>
                  <router-link :to="{ name: 'episode', params: { id: data.id } }" class="dropdown-item">
                    <i class="ri-eye-fill text-muted me-2 align-bottom"></i> View
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col">
          <div class="team-profile-img">
            <div class="img-thumbnail rounded-circle">
              <router-link :to="{ name: 'episode', params: { id: data.id } }">
                <div class="avatar-lg flex-shrink-0" v-if="!data.urlAvatar">
                  <div class="avatar-title rounded-circle">{{ data.title[0].toUpperCase() }}</div>
                </div>
                <img v-bind:src="data.urlAvatar" alt="" class="avatar-lg img-fluid d-block rounded-circle" v-if="data.urlAvatar">
              </router-link>
            </div>
            <div class="team-content">
              <router-link :to="{ name: 'episode', params: { id: data.id } }" class="d-block">
                <h5 class="fs-16 mb-1"><span class="text-muted">{{ getSeasonEpisode() }}</span>{{ data.title }}</h5>
              </router-link>
              <p class="mb-0">{{ data.short }}</p>
              <p class="text-muted mb-0"><i class="ri-calendar-2-line"></i> {{ new Date(data.createdAt).toLocaleString() }}</p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col">
          <div class="row text-muted text-center">
            <div class="col-6 border-end border-end-dashed">
              <h5 class="mb-1">{{ getSecondsOfEpisode }}</h5>
              <p class="text-muted mb-0">Minutes</p>
            </div>
            <div class="col-6">
              <h5 class="mb-1">{{ data.stat.posts }}</h5>
              <p class="text-muted mb-0">Posts</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>